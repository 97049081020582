@use 'ui/styles/variables' as vars;

.OCRToggle {
  background: none;
  margin: auto;

  @include vars.mediaQuery('md') {
    margin: initial;
  }
}

.OCRText {
  color: var(--core-ink);
  line-height: 1.5em;
  max-width: 768px;
  margin: 0 auto;
  text-align: left;
  padding: 1rem;

  @include vars.mediaQuery('md') {
    max-width: 960px;
    padding: 0;
  }
}

.blur {
  mask-image: linear-gradient(to bottom, black 0%, transparent 85%);
  --webkit-mask-image: linear-gradient(to bottom, black 0%, transparent 85%);

  p {
    margin: 0;
  }
}

.ctaWrapper {
  text-align: center;
  margin-bottom: 2rem;
}

.cta {
  margin-left: auto;
  margin-right: auto;
}

.ctaLabel {
  font-weight: bold;
}

.expanded {
  margin: auto;
}