@use "ui/styles/variables" as vars;

.ThumbsActionsContainer {
  display: flex;
  flex-direction: row-reverse;
  gap: 0.5rem;
  justify-content: center;
  padding-bottom: 0.5rem;

  @include vars.mediaQuery("md") {
    flex-direction: row;
  }
}

.ThumbsActions {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;


  @include vars.mediaQuery("md") {
    align-items: center;
  }

  button {
    width: 2.5rem;
  }
}

.ThumbsActionsContainer.deprecated {
  button {
    width: auto;
  }
}