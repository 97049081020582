@use 'ui/styles/variables' as vars;

.SearchPage {
  background-color: #fff;
  padding: 0px 16px;

  @include vars.mobileOnly() {
    padding: 0px 0px;
  }

  width: 100%;

  .Results {
    display: flex;
    flex-direction: column;
    background-color: var(--ui-divider);
    gap: 1px;
  }

}

.SearchResultActions {
  display: flex;
  flex-direction: column;
  gap: 32px;
  background-color: #fff;
  position: relative;
  //overlap the divider on the last result item
  margin-top: -2px;
  padding-top: 2px;
  padding-bottom: 2px;

  .DividerContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-height: 40px;
  }

  button {
    border-color: var(--ui-divider) !important;
  }

  .LoadMore {}

  .AlertMe {
    &.desktop {
      display: none;

      @include vars.mediaQuery('md') {
        display: flex;
        position: absolute;
        right: 32px;
      }
    }

    &.mobile {
      display: flex;
      margin: 0 auto;

      @include vars.mediaQuery('md') {
        display: none;
      }
    }
  }

  .Divider {
    position: absolute;
  }
}

.pageNumber {
  background-color: #fff;
  margin: -1px 0px;
  text-transform: uppercase;
  font-size: .875rem;
}