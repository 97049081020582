// .SearchTagDropdown {
// }

.SelectedPills {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-height: 40px;
  padding: 8px;

  .Pills {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }
}

.TagItem {
  svg {
    opacity: 1 !important;
  }

  text-transform: capitalize;
}