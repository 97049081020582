.Container {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
}

.Clip {
  position: relative;
  padding: 11px;
  border: 1px solid #d3d3d3;
  margin-bottom: 24px;
  width: 100%;
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  margin-left: auto;
  margin-right: auto;
  left: 0px;
  right: 0px;
}

.ClipImage {
  max-width: 100%;
  height: auto;
  width: 100%;

  @media print {
    max-height: 70dvh;
    width: auto;
    margin: auto;
  }
}

.NewspageImage {
  max-height: 100%;
  width: auto;
}

.MobileClip {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  left: 0px;
  right: 0px;
  border: 1px solid #d3d3d3;
  border-width: 1px 0px;
  padding: 0;
  margin: 0 -16px;
}

.SelectContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 100%;

  @media print {
    display: none;
  }

}

.DotContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 48px;
  background: transparent;
}

.SelectDot {
  display: flex;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: #DDDDDD;

  &.active {
    background-color: #A1DAFC;
  }
}

.ClipOverlay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #ffffff;
  padding: 20px;
  font-size: 15px;
  line-height: 24px;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 1;
  background-color: rgba(0, 0, 0, 0.85);

}

.ImageContainer {
  overflow: hidden;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 1;

  &.restrictHeight {
    height: 500px;
  }

  &.Deny {
    min-height: 250px;
  }
}

.NewspageContainer {
  display: flex;
  justify-content: center;
  margin: 16px 0;
}

.Body {
  font-size: 14px;
  line-height: 24px;
  color: #FFFFFF;
  margin-bottom: 16px;
}

.ImageDenyWrapper {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 1;
}

.BlurOverlay {
  backdrop-filter: blur(7px);
  flex-grow: 1;
}

// this exists because it's the best way I could find to correctly
// space the deny content based on the image container's height.
.ImageDenySpacer {
  flex: 0 0 min(120px, calc(60% - 120px));
}

.ImageDenyContent {
  background-color: rgba(20, 20, 20, 0.8);
  backdrop-filter: blur(7px);
  color: white;
  margin: 0 2em 2em;
  padding: 2em;
  border: 1px solid var(--ui-mutedText);
  border-radius: 16px;
  text-align: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.5em;
  line-height: 1.2em;

  @media (max-width: 480px) {
    font-size: 1.2em;
    padding: 1.5em;
  }

  p {
    margin-bottom: 1em;
  }
}