.ImageContainer {
  display: flex;
  position: relative;
  height: 260px;
  width: 100%;
  background-color: var(--background-gray);
}

.ClipImage {
  object-fit: contain;
  position: inherit !important;
}