@use 'ui/styles/variables' as vars;

.ClippingFooter {
  display: flex;
  padding: 1.5rem 1rem 1rem 1rem;
  background-color: var(--core-slate);
  color: white;
  position: relative;
  overflow: hidden;
  gap: 1rem;
  flex-direction: column;

  @include vars.mediaQuery("md") {
    justify-content: space-between;
    flex-direction: row;
  }
}

.ClippingTags {
  overflow: hidden;
  align-content: center;
  flex-wrap: wrap;
  display: flex;
  justify-content: flex-start;
}

.Tags>span:first-child {
  color: white;
  font-size: 0.875rem;
  font-weight: 700;

}

.Tags>span a {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
}

.PublishedNote {
  display: flex;
  flex-direction: column;
  font-size: 0.75rem;

  @include vars.mediaQuery("md") {
    align-items: flex-end;
    text-align: right;
    white-space: nowrap;
    margin-left: auto;
  }
}

.PublishedNote a {
  color: white;
  text-decoration: underline;
  margin-right: 0.5rem;
}

.PublishedNote a:hover {
  text-decoration: none;
  color: var(--bs-dropdown-link-hover-color);
}

.italic {
  font-style: italic;
}