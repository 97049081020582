@use 'ui/styles/variables' as vars;

.SearchInput {
  border-radius: 0px;
  border-right: 0px;
}

.LabeledInput {
  height: inherit;
  position: relative;
  display: block;



  &::before {
    width: fit-content;
    display: flex;
    white-space: nowrap;
    content: attr(data-label);
    position: absolute;
    top: 0;
    left: 0;
    padding: 6px 10px;
    font-size: 14px;
    color: var(--ui-mutedText);
  }

  input {
    padding-top: 30px;
    padding-left: 44px;
  }

  &:last-child {
    input {
      border-right: 1px solid var(--ui-divider);
    }
  }

  button {
    position: absolute;
    bottom: 6px;
    left: 6px;
    height: 32px;
    width: 32px;

    border-radius: 100%;
    background-color: var(--background-lightBlue);
    padding: 3px;

    &:focus-visible {
      outline: 2px solid var(--ui-orange);
    }

    svg {
      fill: var(--ui-mutedText);
    }
  }
}

.PrimaryFilters {
  display: flex;
  flex-direction: column;
  max-width: 1280px;
  margin: 0 auto;
  position: relative;
  padding: 16px 16px 0px 16px;
  width: 100%;

  @include vars.mediaQuery('md') {
    flex-direction: row;
  }

  .SearchButton {
    margin-top: 16px;
    border-radius: 100px;
    height: inherit !important;

    @include vars.mediaQuery('md') {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      margin-top: 0px;
      height: inherit !important;
    }


    padding: 0.5rem 1rem;

    &:focus {
      z-index: 2;
    }
  }

  >span {
    width: 100%;
    z-index: 1;
    position: relative;

    &:focus-within {
      z-index: 2;
    }

    div {
      color: var(--ui-mutedText);
      height: 100%;

      input {
        height: 100% !important;

        &:focus~label {
          opacity: 1;
          transform: scale(0.85) translateY(-0.7rem) translateX(-0.3rem);
        }
      }

      &>input:not(:placeholder-shown)~label {
        opacity: 1;
        transform: scale(0.85) translateY(-0.7rem) translateX(-0.3rem);
      }
    }

    input {
      z-index: 1;
      box-sizing: border-box;
      color: var(--ui-mutedText);


      &:focus {
        box-shadow: 0 0 0 2px var(--ui-orangeShadow);
        border: 1px solid var(--ui-orange);
        z-index: 100;
      }

      height: 62px;
      border-radius: 0px;
      border-bottom-color: transparent;

      @include vars.mediaQuery('md') {
        border-right-color: transparent;
        border-bottom-color: var(--ui-divider);
      }
    }

    &:first-of-type {
      input {
        border-radius: 4px 4px 0px 0px;
      }
    }

    &:last-of-type {
      input {
        border-radius: 0px 0px 4px 4px;
        border-bottom-color: var(--ui-divider);
      }
    }

    @include vars.mediaQuery('md') {
      &:first-of-type {
        label {
          margin-left: .5rem;
        }

        input {
          border-top-left-radius: 50rem;
          border-bottom-left-radius: 50rem;
          padding-left: 1.25rem;
        }
      }

      &:last-of-type {
        input {
          border-radius: 0px;
          border-right-color: var(--ui-divider);
        }
      }
    }
  }
}

.SecondaryFilters {
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin: 0 auto;
  max-width: 1280px;
  overflow-x: scroll;
  padding: 2px 16px 16px 16px;
  width: 100%;
  align-items: center;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

@-moz-document url-prefix() {
  .LabeledInput button {
    top: calc(-180%);
    bottom: unset; 
  }
}