@use "ui/styles/variables" as vars;

.TagPill {
  font-family: var(--font-family);
  font-size: 0.875rem;
  line-height: 1.2;
  color: #3e3e3e;
  background-color: var(--background-gray);
  border-radius: 18px;
  font-weight: bold;
  text-transform: capitalize;
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  padding: 0;
  outline: 4px solid transparent;
  width: fit-content;

  >button,
  >span {
    display: flex;
    align-items: center;
    font-weight: bold;
    text-transform: capitalize;
    padding: 0 .5rem;
    min-height: 24px;
    overflow-wrap: break-word;
    word-break: break-word;

    &:focus {
      outline: 2px solid var(--ui-orange) !important;
    }
  }

  >button:first-child {
    border-radius: 5rem 0 0 5rem;
    padding: 2px .5rem 2px .75rem !important;
  }

  >button:last-child {
    border-radius: 0 5rem 5rem 0;
    padding: 2px .5rem !important;
  }

  &:hover[href],
  &.addedTag {
    outline-color: var(--background-gray);
    outline-width: 2px;
    transition: outline-width 0.3s ease-out;
  }

  &:focus {
    outline: 2px solid var(--ui-orange) !important;
  }
}

.InputTags[type='text'] {
  border-radius: 5rem;
  border: 1px solid var(--ui-divider);
  padding: 2px .75rem;
  font-size: .875rem;

  &:focus {
    outline: 2px solid var(--ui-orange) !important;
  }
}

.TagLabel {
  display: flex;
  align-items: center;
  font-weight: bold;
  text-transform: capitalize;
  padding: 2px .5rem;
  min-height: 24px;
}

.ActionBtn {
  display: flex;
  border-left: 1px solid var(--ui-disabledText);
  padding-left: 12px;
  padding-right: 0px;

  &:hover {
    background-color: var(--ui-disabledText);
  }

  &:focus {
    background-color: var(--ui-disabledText);
    outline: 2px solid var(--ui-orange);
  }
}

.ActionIcon {
  height: 16px;
  width: 16px;
  fill: var(--core-slate)
}

.Container {
  position: relative;
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.5rem;
}

.Title {
  font-size: 14px;
  color: var(--core-ink);
  margin-right: 4px;
}

.CollapsableTags {
  .Container .Title {
    margin-left: 0;
  }

  .Title>span {
    color: var(--bs-light) !important;
    top: initial !important;
  }

  @include vars.mediaQuery("md") {
    max-width: 50%;
    display: flex;
    justify-content: start;
    align-items: center;
  }

  svg {
    fill: white;
    height: 1em;
  }


  button {
    display: inline;
    font-size: 0.75rem;
    font-style: italic;
    text-decoration: underline;
    white-space: nowrap;
    color: white !important;

    svg {
      fill: white !important;
    }

  }

  button:hover {
    color: white;

    svg {
      fill: white;
    }


  }

  .Container>span {
    position: relative;
    top: -1000px;
    height: 0;
    width: 0;
    margin-left: -0.5rem;
  }

  .Container.open span {
    display: inline;
    height: 1.5rem;
    top: initial;
    width: auto;
    margin-left: 0;
  }

  span.Title {
    color: white;
    display: inline;
    top: auto;
    height: 1.5rem;
    width: auto;
  }

  span:nth-child(2),
  span:nth-child(3),
  span:nth-child(4) {
    display: inline;
    top: auto;
    height: 1.5rem;
    width: auto;
    margin-left: 0;

    span {
      width: auto;
      top: initial;
    }
  }
}

.Checkbox {
  >input {
    height: 14px;
    width: 14px;
    border: 1px solid;
    background-color: var(--background-gray);
  }
}