// .ClippingsContent {
// }

.ClippingsHeader {
  background-color: var(--background-beige);
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 100;
  position: sticky;
  top: 0;
}

.ClippingsHeaderCont {
  background-color: inherit;
  width: 100%;
  max-width: 1400px;
  margin: auto;
}

.ClippingsHeaderRow1 {
  background-color: inherit;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--ui-divider);
  padding-right: 10px;
}

.ClippingsHeaderRow2 {
  background-color: inherit;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 0px 10px 10px;
  overflow-y: auto;
  scrollbar-width: none;
}