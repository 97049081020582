@use 'ui/styles/variables' as vars;

.SearchContainer {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  gap: 6px
}

.Tag {
  display: flex;
  gap: 10px;
  flex-flow: wrap;
}

.TagList {
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
}

.ActionBtnContainer {
  display: flex;
  gap: 10px;
}

.NoTagsBody {
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;

  @include vars.mobileOnly() {
    height: 320px;
  }

  @include vars.tabletOnly() {
    height: 320px;
  }
}

.NoTagsContainer {
  font-size: 20px;
  font-style: italic;
  gap: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.NoTagsText {
  font-size: 20px;
  font-style: italic;
  text-align: center;
}

.Footer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}