@use 'ui/styles/variables' as vars;

.buttonContainer {
  flex: 1;
  margin: .5em 0;
  justify-content: center;
  width: 100% !important;
  align-items: center;

  @include vars.mediaQuery('md') {
    width: 90% !important;
  }
}

.facebookButton {
  width: 100% !important;
  display: flex;

  svg {
    flex: 1;
  }

  span {
    flex: 12;
  }
}

.facebookLogout {
  width: 100% !important;
  text-align: center;
  padding: 0.5rem 1rem;
  height: 40px;
  font-weight: normal;
  background: transparent !important;
  margin-top: .5em;
}