@use 'ui/styles/variables' as vars;

.AdvertisementLdrContainer {
  display: flex;
  justify-content: center;
}

.AdvertisementLdr {
  max-width: 728px;
  max-height: 90px;

  @include vars.mobileOnly() {
    max-width: 320px;
    max-height: 50px;
  }
}

.AdvertisementMrec {
  max-width: 300px;
  max-height: 250px;

  @include vars.tabletOnly() {
    max-width: 125px;
    max-height: 125px;
  }
}