@use 'ui/styles/variables' as vars;

.Pad {
  display: flex;
  flex-direction: column;
}

.Top {
  width: 100%;
  max-width: 100%;

  @include vars.mediaQuery('md') {
    max-width: 70%;
  }
}

.Middle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  width: 100%;

  @include vars.mediaQuery('md') {
    justify-content: space-between;
    max-width: 960px;
  }

  @media print {
    text-align: center;
  }
}

.ClippingActionsWrapper {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}

.Bullet {
  font-size: 1.5rem;
}

.ClippingActionsWrapper>div {
  justify-content: center;
}

.Bottom {
  display: flex;
  flex-direction: column;

  @include vars.mediaQuery('md') {
    padding: 0;
  }

  @media print {
    display: none;
  }

  .Breadcrumbs {
    justify-content: center;
  }
}

.BottomContain {
  @include vars.mediaQuery('md') {
    max-width: 66%;
  }
}

.BottomActions {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 32px 0;
}

.Bullet {
  display: flex;
  margin: 0 16px;
}

.Main {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  margin-right: 16px;
  margin-bottom: 24px;

  @include vars.mediaQuery('md') {
    max-width: 66%;
  }
}

.SideBar {
  width: 100%;
  max-width: 30%;
}

.Title {
  font-size: 24px;
  color: var(--core-ink);
  font-weight: bold;
  margin: 8px 0;

  &.italic {
    font-style: italic;
  }
}

.MobileInfo {
  margin: 12px 0;
}

.ArticleBody {
  color: var(--core-ink);
  font-size: 1rem;
  line-height: 24px;
  margin: 0 1rem;

  @include vars.mediaQuery("md") {
    margin: 0;
  }
}

.Citation {
  background: none;

  @media print {
    display: none;
  }
}

.Thumbnail {
  display: grid;
  grid-template-columns: 1fr;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  align-items: center;
  height: 356px;
  width: 256px;
  max-width: 100%;
}

.ImageContainer {
  position: relative;
  grid-row-start: 1;
  grid-column-start: 1;
  align-self: center;
  text-align: center;
}

.TrialButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.NewspageImage {
  max-width: 100%;
  max-height: 100%;
  width: auto;
}

.ViewAllButton {
  margin-top: 16px;
  margin-left: auto;
  margin-right: auto;

  @include vars.mediaQuery("md") {
    margin-top: 32px;
  }
}

.Container {
  width: 100%;

  @include vars.mediaQuery("md") {
    width: 960px;
  }
}

.MoreClippings {
  margin: 0 0.875rem;

  @include vars.mediaQuery("md") {
    margin: 0;
  }
}