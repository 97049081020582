@use 'ui/styles/variables' as vars;

.ClippingDetail {
  flex-direction: column;
  height: 0;
  overflow: hidden;
  position: relative;
  display: block;
  text-align: center;

  @include vars.mediaQuery("md") {
    text-align: left;
    padding: 0;
    align-items: center;
    gap: 1.25rem;
    justify-content: flex-start;

    nav {
      margin-bottom: 1rem;
    }
  }

  .CloseDetails {
    position: absolute;
    top: 1.75rem;
    right: 1rem;

    @include vars.mediaQuery("md") {
      right: 0;
    }
  }
}

.ClippingDetail.expanded {
  height: auto;
}



.DetailActions {
  display: flex;
  font-size: 1.5rem;
  align-items: center;
  gap: 0.75rem;
  justify-content: center;
  margin-top: 1rem;

  button {
    padding: 0;
    flex-wrap: nowrap;
  }

  @include vars.mediaQuery("md") {
    justify-content: flex-start;
    margin-top: 1.25rem;
  }
}

.Thumbnail {

  @include vars.mediaQuery("md") {
    float: left;
    margin: 0.5rem 1rem 0.5rem 0;
  }
}

.Divider {
  display: flow-root;

  @include vars.mediaQuery("md") {
    width: auto !important;
  }
}

.ClippingActionsWrapper {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: flex-start;
  justify-content: center;

  @include vars.mediaQuery("md") {
    gap: 0.75rem;
  }
}

.Bullet {
  font-size: 1.5rem;
}

.ClippingActionsWrapper>div {
  justify-content: center;
}