@use 'ui/styles/variables' as vars;

.form {
  @include vars.mediaQuery('md') {
    padding: 1em;
  }

  small {
    line-height: 1.2;
  }
}

.inputGroup {
  margin-bottom: 1em;
}

.label {
  color: #262626;
  display: block;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.23;
  padding-bottom: 0.5em;
}

.formControl {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #535353;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.registerActions {
  width: 100% !important;
  display: flex;
}

.showPassword {
  margin: 1em 0 1em 0;
}

.goBackButton {
  margin-left: 1em;
  margin-top: .6em;
  background: transparent !important;
}

.fixItButton {
  margin: .5em 0 !important;
}

.alertText {
  display: flex;
  flex-direction: column;
  word-break: break-word;

}