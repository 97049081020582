@use 'ui/styles/variables' as vars;

.FormBody {
  padding: 1em;

  @include vars.mediaQuery('md') {
    padding: 1.5em;
  }
}

.Heading {
  color: var(--core-red);
  font-size: 1.5rem;
  text-align: center;
  margin: 0 0 1em;
}

.ButtonGroup {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0px;
}

.ButtonContainer {
  display: flex;
  flex: 1;
  margin: .5em 0;
  justify-content: center;
  width: 100% !important;

  @include vars.mediaQuery('md') {
    width: 90% !important;
  }

  align-items: center;
}

.EntryButton {
  width: 100% !important;
  display: flex;

  svg {
    flex: 1;
  }

  span {
    flex: 11;
  }
}

.ChooseOption {
  margin-top: 2em;
  color: var(--ui-mutedText);
  text-align: center;
}

.AccountNotFound {
  text-align: center;
  margin-top: 2em;

  p {
    margin-top: 1em;
  }

  button {
    margin-left: auto;
    margin-right: auto;
  }
}

.AccountNotLinked {
  margin-top: 3em;
}

.OrDivider {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 1rem 0;
  padding: 0 1.5rem;
  white-space: nowrap;
  width: 100%;

  &:before {
    background-color: var(--background-gray);
    content: " ";
    display: inline-block;
    height: 1px;
    margin-right: 1.25rem;
    width: 100%
  }

  &:after {
    background-color: var(--background-gray);
    content: " ";
    display: inline-block;
    height: 1px;
    margin-left: 1.25rem;
    width: 100%
  }

  span {
    font-size: 14px;
    font-weight: 600;
    color: var(--ui-mutedText);
    text-align: left;
  }
}

.Step1 {
  color: var(--ui-mutedText);
  margin: 1em 0 0;
  text-align: center !important;
}