@use 'ui/styles/variables' as vars;

.ImageFrame {
  background-color: var(--core-ink);
  width: 100%;
}

.Clip {
  margin: auto;
}

.ImageContainer {
  overflow: hidden;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 1;

  &.restrictHeight {
    height: 500px;
  }
}

.ClipImage {
  max-width: 100%;
  height: auto;
  width: 100%;
  display: block;
  background-color: var(--ui-mutedText);
  border: 1px solid var(--core-slate);

  @media print {
    max-height: 70dvh;
    width: auto;
    margin: auto;
  }
}