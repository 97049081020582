.Container {
  position: relative;
  background: none;
  padding: 12px;
  border-radius: 6px;
  border: none;

  &.background {
    background: #E4F2FB;
    border: 1px solid #d0e4f0;
  }
}

.SearchLabel {
  font-weight: bold;
}