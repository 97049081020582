@use 'ui/styles/variables' as vars;



.SearchResult {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100px;
  padding: 16px 0 24px;
  background-color: #fff;
  gap: 20px;
  overflow: hidden;

  @include vars.mediaQuery('md') {
    flex-direction: row;
    justify-content: start;
    flex-wrap: wrap;
  }

  &.active {
    background-color: var(--background-faintBlue) !important;
  }

  .ThumbnailContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 12px;
    position: relative;
    height: fit-content;

    &:hover {
      cursor: pointer;

      .ImageContainer {
        .PublisherExtraCover {
          height: auto;

          a {
            display: inline-block;
          }
        }
      }
    }

    @include vars.maxSize('xl') {
      .ResultIcon {
        min-width: calc(24px + 16px);
        min-height: calc(24px + 16px);
        fill: var(--core-ink);
        position: absolute;
        z-index: 1;
        bottom: 8px;
        left: 8px;
        padding: 8px;
        background-color: #ffffffc1;
        border-radius: 5px;
        fill: var(--core-slate);
        z-index: 3;
      }
    }

    .ResultIcon {
      min-width: 32px;
    }

    .ResultThumbnail {
      min-width: 330px;
      min-height: 200px;
      // aspect-ratio: 4/3;
    }

    .ImageContainer {
      position: relative;
      width: 100%;
      height: fit-content;



      .PublisherExtraCover {
        display: flex;
        flex-direction: column;
        background-color: rgba(0, 0, 0, 0.7);
        color: #fff;
        font-weight: bold;
        position: absolute;
        width: 100%;
        height: 100%;
        padding: 20px;
        z-index: 3; //TODO fix/discuss this
        gap: 8px;
        align-items: center;
        top: 0;

        @include vars.mobileOnly() {
          height: auto;
        }

        a {
          display: none;
          text-align: center;

          @include vars.mobileOnly() {
            display: inline-block;
          }
        }

      }
    }

  }

  .NewspageDetails {
    display: flex;
    flex-direction: column;
    gap: 4px;
    min-width: min-content;

    @include vars.mobileOnly() {
      padding-top: 8px;
      order: 2;
    }

    h2 {
      font-size: 1.25em;
      font-weight: bold;
    }

    &:visited h2 {
      color: var(--ui-linkVisited);
    }

    span {
      display: flex;
      align-items: center;
      gap: 8px;
      color: var(--ui-mutedText);

      svg {
        fill: var(--ui-mutedText);
      }
    }
  }

  .ArticleResult {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    @include vars.mobileOnly() {
      flex-direction: column;
      gap: 16px;

      >button {
        align-self: flex-end;
        margin-right: 12px;
      }

      &.NoKeywordResult {
        .NewspageImageContainer {
          background-color: var(--background-gray);
          display: flex;
          justify-content: center;

          .NewspageImage {
            position: relative;
            z-index: 2;
          }
        }
      }
    }



    .LeftContainer {
      display: flex;
      flex-direction: column;
      gap: 16px;

      @include vars.mediaQuery('md') {
        flex-direction: row;
      }

      @include vars.mobileOnly() {
        width: 100%;
        gap: 0px;
      }

      .ThumbnailContainer {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        gap: 12px;

        .ResultIcon {
          min-width: 32px;
          fill: var(--core-slate);
        }



        .ResultThumbnail {
          position: relative;
          min-width: 330px;
          min-height: 199px;
          text-align: center;

          // make sure the image stays in front of the skeleton
          &:before {
            z-index: 1;
          }

          img {
            border: 1px solid var(--ui-divider);
            position: relative;
            z-index: 2;
          }

          @include vars.mobileOnly() {
            min-width: 100%;
            aspect-ratio: 5/3;
          }
        }

        .ArticleResultThumbnail {
          border: 1px solid var(--ui-divider);
          position: relative;
          min-width: 330px;
          min-height: 199px;

          // make sure the image stays in front of the skeleton
          &:before {
            z-index: 1;
          }

          img {
            position: relative;
            z-index: 2;
            width: 100%;
          }

          @include vars.mobileOnly() {
            min-width: 100%;
            aspect-ratio: 5/3;
          }
        }

        @include vars.mobileOnly() {
          position: relative;
          min-width: 100%;

          a {
            width: 100%;
          }

          .ResultIcon {
            min-width: calc(24px + 16px);
            min-height: calc(24px + 16px);
            fill: var(--core-ink);
            position: absolute;
            z-index: 3;
            bottom: 8px;
            left: 8px;
            padding: 8px;
            background-color: #ffffffc1;
            border-radius: 5px;
            fill: var(--core-slate);
          }

          .ResultThumbnail {
            min-width: 100%;

            img {
              width: 100%;
              height: auto;
            }
          }
        }
      }

      .Title {
        display: flex;
        flex-direction: column;

        @include vars.mobileOnly() {
          flex-direction: column-reverse;
          padding: 0px 16px;
        }
      }

      .PublisherExtraInfo {
        display: flex;
        gap: 8px;
        margin-top: 8px;
        color: var(--ui-mutedText);

        @include vars.mobileOnly() {
          order: 1;
        }

        div {
          display: flex;
          gap: 8px;
          flex-wrap: wrap;

          a {
            text-decoration: underline;

            sup {
              text-decoration: none;
            }
          }

          .BlogLink {
            a {
              color: var(--ui-mutedText);

              &:hover {
                text-decoration: none;

              }
            }
          }
        }
      }
    }
  }
}


.ResultDetail {
  display: flex;
  flex-direction: row;
  gap: 4px;
  min-width: 100%;

  span {
    gap: 8px;
    color: var(--ui-mutedText);
  }
}

.PageHitPagination {
  margin-top: 16px;

  @include vars.mobileOnly() {
    order: 3;
  }

  p {
    color: var(--ui-mutedText);
    margin-bottom: 8px;
  }

  @include vars.mobileOnly() {
    margin-top: 4px;
    font-size: 0.875em;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}