@use 'ui/styles/variables' as vars;

nav.Breadcrumbs {
  @include vars.mediaQuery("md") {
    ol {
      justify-content: flex-start;

    }
  }

  ol li:last-of-type {
    font-weight: normal;
  }
}

nav.Breadcrumbs_deprecated {
  margin: auto;

  @include vars.mediaQuery("md") {
    ol {
      justify-content: center;
    }
  }
}