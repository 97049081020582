.Container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.CurrentTags {
  display: flex;
  align-items: center;
  flex: 1 0 100%;
  width: 100%;
  background-color: var(--background-lighter);
  border-top: 1px solid var(--ui-divider);
}

.TagContainer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 4px;
  margin-bottom: 12px;
  width: 100%;
  padding: 0px !important;
  font-size: 14px;

  &>* {
    padding: 0.375rem 0.75rem
  }

  &:focus-within {
    outline: 2px solid var(--ui-orange);
  }
}

.AddTagNew {
  border: 0 none;
  outline: 0 none;
  margin-top: 4px;
  margin-left: 4px;
  margin-bottom: .25rem;
  width: 100%;

  &:-ms-input-placeholder,
  &::placeholder {
    vertical-align: text-top;
  }

  &:first-child {
    margin-left: 0;
  }

  &:focus {
    outline: 0 none;
  }
}

.RecentContainer {
  display: flex;
  width: 100%;
  gap: 6px;
  align-items: baseline;
  flex-wrap: wrap;
}

.RecentTag {
  background-color: white;
  border: solid 1px #ebebeb;
  overflow-wrap: break-word;
  word-break: break-word;
}

.Uppercase {
  text-transform: uppercase;
}

.TagHeader {
  background-color: var(--background-lighter);
  border-bottom: 1px solid var(--ui-divider);
  width: 100%;
  font-size: 14px;
  font-weight: bold;
}

.EditTagsContainer {
  min-height: 150px;
  max-height: 180px;
  overflow-x: hidden;
  overflow-y: visible;
  width: inherit;
}