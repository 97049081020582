.Heading {
  color: var(--core-red);
}

.Body {
  text-align: center;
}

.copy {
  padding: 16px;
}

.CTA {
  margin-left: auto;
  margin-right: auto;
}

.Continue {
  padding: 16px;
}

.GoBack {
  font-size: 14px;
  margin-top: 20px;
  padding: 16px;
}

.member {
  margin: 16px 16px 0px 16px;
}