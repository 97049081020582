@use 'ui/styles/variables' as vars;

.Container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  justify-content: flex-end;
  width: 100%;
  overflow-y: auto;
  scrollbar-width: none;
  padding: 0px 10px;

  @include vars.tabletOnly() {
    background-color: inherit;
  }

  @include vars.mobileOnly() {
    background-color: inherit;
  }
}

.MainBtn,
.SelectBtnContainer {
  @include vars.tabletOnly() {
    border-left: 1px solid #c9c8c8;
    margin-left: 4px;
    position: sticky;
    right: 0;
    background-color: inherit;
    padding-left: 10px;
  }

  @include vars.mobileOnly() {
    border-left: 1px solid #c9c8c8;
    margin-left: 4px;
    position: sticky;
    right: 0;
    background-color: inherit;
    padding-left: 10px;
  }

}

.MainBtn {
  @include vars.tabletOnly() {
    padding-top: 1px;
    padding-bottom: 1px;
  }

  @include vars.mobileOnly() {
    padding-top: 1px;
    padding-bottom: 1px;
  }
}

.SelectBtnContainer {
  padding-right: 10px;
}