@use 'ui/styles/variables' as vars;

.formContainer {
  margin: 1em .5em 0;
}

.inputGroup {
  margin-bottom: 1em;
}

.label {
  color: var(--core-ink);
  display: block;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.23;
  padding-bottom: 0.5em;
}

.formControl {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--core-ink);
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid var(--background-gray);
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.showPassword {
  margin: 16px 0;
}

.forgotPassword {
  text-align: center;
  margin-top: .5em;

  a {
    color: var(--ui-mutedText) !important;
    font-size: 14px;
  }

  a:hover {
    text-decoration: underline !important;
  }
}

.ncomSubmitButton {
  display: flex;
  justify-content: center;
  width: 100% !important;
  align-items: center;

  button {
    display: flex;

    @include vars.mediaQuery('md') {
      margin: 0 1em;
    }

    width: 100% !important;

    svg {
      flex-shrink: 1;
    }
  }
}

.goBackButton {
  margin-left: .25em;
  margin-top: .5em;
  background: transparent !important;
}