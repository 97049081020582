@use "ui/styles/variables" as vars;

.Actions {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  justify-content: left;
  margin-bottom: 12px;

  @include vars.mediaQuery("md") {
    flex-direction: row;
  }

  @media print {
    display: none;
  }

  button {
    width: 2.5rem;

    @include vars.mediaQuery("md") {
      width: auto;
    }
  }
}

.Actions.deprecated button {
  width: auto;
}

.TileActions {
  display: flex;
  gap: 0.25rem;
  height: 100%;
  align-items: center;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}