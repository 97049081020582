@use 'ui/styles/variables' as vars;

.Container {
  align-items: center;
  color: var(--bs-white);
  display: flex;
  font-size: 0.8125rem;
  gap: 0.5rem;
  justify-content: center;
  margin: 1rem;

  @include vars.mediaQuery('md') {
    color: var(--ui-mutedText);
    justify-content: flex-start;
  }

  &.invert {
    color: var(--bs-white);
  }
}

.Tile .UserNameLink {
  font-size: 0.875rem;
}

.UserNameLink {
  color: var(--core-ink);
  font-size: 1rem;

  &:hover {
    text-decoration: underline;
  }

  @include vars.mediaQuery('md') {
    color: var(--ui-mutedText);

    &:hover {
      color: var(--ui-link);
    }
  }

  span {
    font-weight: bold;
  }

  &.invert {
    color: var;
  }

}


.ClippedBy {
  white-space: nowrap;
}

.UserDetails {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  border-radius: 0.25rem;
  color: var(--ui-mutedText);

  &.invert {
    color: var(--ui-divider);
  }

  @include vars.mediaQuery('md') {
    color: var(--ui-mutedText);
  }

  &:focus-visible {
    outline-offset: 2px;
  }
}

.deprecated.Container {
  font-size: 13px;
}


.deprecated .ClippedBy {
  flex-shrink: 0;
}

.CreatedAtContainer {
  display: inline-block;
}