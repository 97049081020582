.Options {
  display: flex;
  color: var(--core-ink);
  flex-wrap: wrap;
  gap: 8px;

}

.CitationContainer {
  font-size: 12px;
  line-height: 14px;
  color: var(--ui-mutedText);
  margin-bottom: 24px;
  cursor: pointer;
}