// .ClippingsTagDropdown {
// }

.SelectedPills {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-height: 40px;
  padding: 8px;

  .Pills {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    overflow: hidden;
  }
}

.Disabled {
  fill: var(--ui-disabledText)
}

.TagManagerBtn {
  margin-top: 24px
}

.SelectedTag {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}