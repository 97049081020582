@use 'ui/styles/variables' as vars;

.Body {
  row-gap: 12px;
  display: flex;
  flex-direction: column;

  >input {
    border: 1px solid #DDDDDD;
    width: 100%;
    border-radius: 4px;
    padding: 8px;
    font-size: 1rem;
  }
}