.OCRToggle {
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  margin-bottom: 16px;
  color: var(--theme-palette-dark);
  font-size: 14px;
  align-self: center;
  width: 100%;
  padding: 0;
}

.OCRText {
  font-size: 14px;
  color: #2d2d2d;
  line-height: 16px;
  height: 96px;
  overflow: hidden;
  mask-image: linear-gradient(to bottom, black 0%, transparent 100%);
}

.OCRText.show {
  height: auto;
  overflow: hidden;
  mask-image: none;
}

.ctaWrapper {
  text-align: center;
  margin-bottom: 2rem;
}

.cta {
  margin-left: auto;
  margin-right: auto;
}

.ctaLabel {
  font-weight: bold;
}