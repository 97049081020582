@use 'ui/styles/variables' as vars;

.Container {
  order: 1;
  margin-left: auto;
  padding: 10px 0px;
  border-radius: 4px;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  width: 100%;

  .TagSearchInput {
    max-width: 420px;
  }

  @include vars.mobileOnly() {
    // this is when it needs the margin-left to push it to the right
    flex: unset;
    width: 100%;
    max-width: 100%;

    &:global(.mobileSearchFocused) {
      flex: 100%;
    }
  }

  >div {
    width: inherit;
  }
}