@use 'ui/styles/variables' as vars;

.Title {
  font-size: 24px;
  color: var(--core-ink);
  font-weight: bold;
  margin: 1.75rem 0 1.25rem 0;

  &.italic {
    font-style: italic;
  }

}

.deprecated {
  margin: 0.5rem;

  @include vars.mobileOnly() {
    margin-bottom: 1rem;
  }
}